import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 8`}</h1>
    <p>{`8 weeks into 2020 already, damn. This week is a mostly techno focused recap with a glimpse of experimental music. Skee Mask is back with another remix, Borai & Denham Audio puts you right back in a club, Zoë Mc Pherson released an album, Surgeon and Illian tape, and Blue Hour knows everything about UK club culture.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=621895509/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=414968799/transparent=true/" seamless>
  <a href="http://konx-om-pax.bandcamp.com/album/return-to-cascada">
    Return To Cascada by Konx-om-Pax
  </a>
    </iframe>
    <h1>{`Konx-om-Pax - Rez (Skee Mask Remix)`}</h1>
    <p>{`Skee Mask is back with another remix layered with his signature sounds. Dreamy ambient textures and refreshing breakbeats.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://konx-om-pax.bandcamp.com/track/rez-skee-mask-remix"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3914385186/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://e-beamz.bandcamp.com/album/clouds-across-the-stars">
    Clouds Across The Stars by Borai &amp; Denham Audio
  </a>
    </iframe>
    <h1>{`Borai & Denham Audio - Clouds Across The Stars`}</h1>
    <p>{`Club tool focused EP by Borai & Denham Audio, which lets you think about the summer and forget these rainy days.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://e-beamz.bandcamp.com/album/clouds-across-the-stars"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2587698860/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://sfx-space.bandcamp.com/album/states-of-fugue-sf02">
    States of Fugue [SF02] by Zoë Mc Pherson
  </a>
    </iframe>
    <h1>{`Zoë Mc Pherson - States of Fugue`}</h1>
    <p>{`A rhythm-loaded experimental album from the Berlin-based Zoë Mc Pherson.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "http://sfx-space.bandcamp.com/album/states-of-fugue-sf02"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2642828005/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://iliantape.bandcamp.com/album/it043-surgeon-the-golden-sea">
    IT043 Surgeon - The Golden Sea by Surgeon
  </a>
    </iframe>
    <h1>{`Surgeon - The Golden Sea`}</h1>
    <p>{`Surgeon techno on Illian Tape seems like a perfect match, and it is.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://iliantape.bandcamp.com/album/it043-surgeon-the-golden-sea"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=969099434/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://bluehourmusic.bandcamp.com/album/devotion">
    Devotion by Blue Hour
  </a>
    </iframe>
    <h1>{`Blue Hour - Deviotion`}</h1>
    <p>{`UK club culture at its core with explorations to Techno, Hardcore, and Ambient.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://bluehourmusic.bandcamp.com/album/devotion"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      